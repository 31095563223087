.page-investment .image-item {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto calc(100vh + 30rem);
  background-clip: content-box;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/investment-banner.jpg);
}

.page-investment .image-item .layer-bg-black {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
}

.page-investment .banner-title {
  color: #fff;
  width: 27.5rem;
  position: absolute;
  left: 0;
  bottom: 12rem;
}

.page-investment .banner-title .sub {
  font-size: 1.8rem;
}

.page-investment .banner-title .big {
  font-size: 3.6rem;
  font-weight: bold;
}

.page-investment .banner .main-content-box {
  height: 100%;
}

.page-investment .banner .investmentField {
  width: 55rem;
  position: absolute;
  left: 0;
  bottom: 6rem;
}

.page-investment .banner .investmentField .sub-title {
  font-size: 2rem;
  color: #fff;
}

.page-investment .banner .investmentField .sub-title .name {
  padding-bottom: 0.5rem;
  border-bottom: 1PX solid #fff;
}

.page-investment .banner .investmentField .desc {
  font-size: 1.2rem;
  color: #fff;
  line-height: 1.5;
  margin-top: 1rem;
}

.page-investment .banner .investmentField .list {
  display: none;
  margin-top: 1.6rem;
}

.page-investment .banner .investmentField .list .item {
  width: 12.7rem;
  height: 4rem;
  box-sizing: border-box;
  padding-left: 4.8rem;
  background-position: 1.6rem center;
  background-repeat: no-repeat;
  background-size: 2.2rem auto;
  font-size: 1.6rem;
  color: #fff;
  line-height: 4rem;
  border-radius: 0.6rem;
  border: 1PX solid #fff;
}

.page-investment .slide-2 {
  background: #fff;
  top: 0;
}

.page-investment .slide-2 .list {
  flex-wrap: wrap;
}

.page-investment .slide-2 .list-items {
  flex-wrap: wrap;
  perspective: 30rem;
}

.page-investment .slide-2 .sub-title {
  font-size: 1.2rem;
  color: #3e3a39;
  text-align: center;
  margin-bottom: 2rem;
}

.page-investment .slide-2 .item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 4.4rem;
  margin-bottom: 1rem;
  transform-origin: center top;
}

.page-investment .slide-2 .item img {
  height: 100%;
  width: auto;
}