html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
object,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
ul,
li a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  word-break: normal;
  color: inherit;
  font-family: "PingFang SC",miui,system-ui,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,sans-serif;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

ol,
ul {
  list-style: none;
}

html {
  font-size: 1rem;
}

body {
  background: #000;
  width: 100%;
  touch-action: manipulation;
}

.pc {
  background: #fff;
}

.body-content {
  box-sizing: border-box;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex.auto-width {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.flex.v {
  flex-direction: column;
  flex-wrap: wrap;
}

.flex.h {
  flex-direction: row;
  flex-wrap: wrap;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.main-center {
  justify-content: center;
}

.flex.by-center {
  align-items: center;
}

.flex.center {
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.bg-image {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.layer-model {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  left: 0;
  top: 0;
  z-index: 999;
}

.main-content {
  max-width: 100%;
  min-width: 70rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
}

.body-main {
  margin-top: 0;
}

.main-content-box {
  width: 70rem;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.header-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  background: rgba(0,0,0,.6);
}

.header {
  padding: 0 1.5rem;
  box-sizing: border-box;
  overflow: hidden;
  height: 4.9rem;
}

.header .logo {
  width: 5rem;
  height: 3.5rem;
  background: url("//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/logo.svg") no-repeat center;
  background-size: 100%;
  float: left;
  margin-top: 0.7rem;
}

.header .nav-item {
  line-height: 1.2rem;
  float: right;
  font-size: 1.1rem;
  color: #000;
  margin: 1.85rem 3rem;
  transition: all .3s;
}

.header .nav-item.on {
  color: teal !important;
}

.header .language-item {
  height: 2.5rem;
  line-height: 2.5rem;
  float: right;
  font-size: 1.2rem;
  color: #000;
  transition: all .3s;
}

.header .function-item {
  width: 2.5rem;
  height: 2.5rem;
  float: right;
  background-position: center;
  background-repeat: no-repeat;
}

.header .function-item.search {
  background-image: url("//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/icon-search-black.png");
  background-size: 0.85rem;
  margin-left: 1rem;
}

.header .function-item.more {
  background-image: url("//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/icon-menu-black.png");
  background-size: 1.2rem;
  margin-left: 1rem;
}

.header.white .logo {
  background-image: url("//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/logo.svg");
}

.header.white .nav-item,
.header.white .language-item {
  color: #fff;
}

.header.white .function-item.search {
  background-image: url("//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/icon-search.png");
}

.header.white .function-item.more {
  background-image: url("//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/icon-menu.png");
}

.banner {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner .title {
  width: 48rem;
  text-align: center;
  font-size: 3rem;
  color: #fff;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  line-height: 1;
  margin-top: -1.5rem;
  z-index: 2;
}

.banner .title .arrow {
  width: 2.1rem;
  height: 3.9rem;
  position: absolute;
  top: -0.45rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.banner .title .arrow.left {
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/abanner-arrow-left.png);
  left: -5rem;
}

.banner .title .arrow.right {
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/abanner-arrow-right.png);
  right: -5rem;
}

.banner .image-item {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center center;
  position: relative;
}

.banner .switchs {
  height: 0.2rem;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6rem;
  z-index: 2;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.banner .switchs .dot {
  width: 2rem;
  height: 0.1rem;
  background: rgba(255,255,255,.6);
  float: left;
  margin: 0 0.2rem;
}

.banner .switchs .dot.on {
  background: #fff;
  border: 1PX solid #fff;
}

.test-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.slide-block {
  width: 100%;
  min-height: 30rem;
  height: 100vh;
  transform-origin: center top;
}

.slide-block.placeholder {
  box-shadow: none;
  pointer-events: none;
}

.footer-block {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.footer-block.slide-block .layer-bg {
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/footer-bg.jpg);
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.footer-block .footer {
  width: 100%;
  height: 100%;
}

.footer-block .footer-item-box {
  perspective: 30rem;
}

.footer-block .footer-item {
  width: 66.3rem;
  padding: 2rem 0;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/footer-bg2.jpg);
  background-repeat: repeat;
  border-radius: 1rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: #fff;
  border-bottom: 1PX solid #3e3a39;
}

.footer-block .footer-item .item-box {
  width: 53rem;
}

.footer-block .footer-item .info1 {
  width: 20rem;
}

.footer-block .footer-item .info1 .btn {
  background: #018080;
  width: 8.4rem;
  height: 1.9rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  line-height: 1.9rem;
  text-align: center;
  color: #fff;
  margin-bottom: 0.5rem;
}

.footer-block .footer-item .info1 .text {
  font-size: 0.9rem;
  line-height: 1.6rem;
}

.footer-block .footer-item .info1.style-1 {
  display: flex;
}

.footer-block .footer-item .info1.style-2 {
  display: none;
}

.footer-block .footer-item .info2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: right;
}

.footer-block .footer-item .info2.style-1 {
  display: block;
}

.footer-block .footer-item .info2.style-2 {
  display: none;
}

.footer-block .footer-item .info2 .site {
  border-bottom: 1PX solid #fff;
}

.footer-block .footer-item .site {
  padding: 0.5rem 0;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 6rem auto;
  font-size: 0.9rem;
  padding-top: 2rem;
}

.footer-block .footer-item .area {
  font-size: 0.9rem;
  padding: 0.5rem;
}

.footer-block .footer-item .info3 {
  width: 19rem;
}

.footer-block .footer-item .info3 .img {
  width: 6.2rem;
  height: auto;
  margin-right: 1rem;
}

.footer-block .footer-item .info3 .info-text {
  font-size: 1.2rem;
  line-height: 1.5;
  padding-top: 3rem;
}

.footer-block .footer-item .info4 .site {
  margin: 0.25rem 0;
}

.footer-block .footer-item .info4 .site:first-child {
  margin-bottom: 1rem;
}

.footer-block .infos {
  margin-top: 4rem;
  border-top: 1PX solid #fff;
}

.footer-block .infos .info-text {
  font-size: 0.7rem;
  color: #fff;
  padding: 1.5rem 0;
  line-height: 1.2rem;
}

.footer-block .infos .info-text span {
  font-size: 0.9rem;
  color: #018080;
}

.footer-block .infos .info-text.left {
  padding-left: 6rem;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/logo-4.svg);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 3.2rem auto;
}

.footer-block .infos .info-text.right {
  padding-right: 5rem;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/qr-jb.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.5rem auto;
}

.footer-block .infos-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-block .footer-item-box {
  padding-bottom: 7rem;
}

.footer-block.white.slide-block .layer-bg {
  background: #fff;
}

.footer-block.white .infos-box {
  overflow: hidden;
  width: 100%;
  height: auto;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/footer-bg.jpg);
  background-repeat: repeat;
  z-index: 2;
}

.footer-block.white .footer-item {
  background: none;
  color: #3e3a39;
  border-radius: 0;
}

.footer-block.white .info1.style-1 {
  display: none;
}

.footer-block.white .info1.style-2 {
  display: flex;
}

.footer-block.white .info2.style-1 {
  display: none;
}

.footer-block.white .info2.style-2 {
  display: block;
  text-align: left;
  width: 30rem;
}

.footer-block.white .info2 .name {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1.5rem;
}

.footer-block.white .info4 {
  width: 25rem;
}

.mouse-box {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100vh - 4rem);
  width: 1.6rem;
  margin: 0 auto;
  z-index: 2;
}

.mouse-box .mouse-icon {
  display: block;
  width: 100%;
}

.mouse-box .arrow {
  display: block;
  width: 80%;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.animate__animated.animate__alternate {
  animation-direction: alternate;
}

.header-mobile {
  background: #000;
  height: 6.8rem;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.header-mobile .logo {
  width: 4rem;
  height: auto;
}

.header-mobile .right-btns {
  width: 8.4rem;
  height: auto;
}

.header-mobile .right-btns .index-btn {
  width: 4.2rem;
  height: auto;
  display: block;
}

.header-mobile .right-btns .menu {
  width: 1.8rem;
}

.header-mobile .more-meuns {
  width: 18.25rem;
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(128,128,128,.8);
  opacity: 0;
  transform: translateX(100%);
  transition: all .3s;
  border-bottom-left-radius: 3rem;
  border-top-left-radius: 3rem;
}

.header-mobile .more-meuns.on {
  opacity: 1;
  transform: translateX(0);
}

.header-mobile .more-meuns .close {
  height: 5rem;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/mobile/close.svg);
  background-size: 1.5rem auto;
  background-position: calc(100% - 1.5rem) center;
  background-repeat: no-repeat;
  border-bottom: 1PX solid #fff;
}

.header-mobile .more-meuns .menus-list .item {
  padding: 1.2rem 3rem;
  font-size: 1.6rem;
  color: #fff;
  line-height: 1.5;
  position: relative;
}

.header-mobile .more-meuns .menus-list .item.on {
  background: teal;
}

.header-mobile .more-meuns .menus-list .item.on:before {
  content: "";
  display: block;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.5rem;
  margin-top: -0.3rem;
  background: #fff;
}

.header-mobile .more-meuns .logo {
  width: 100%;
  height: 5rem;
  background-size: 5rem auto;
  background-position: 3rem center;
  background-repeat: no-repeat;
  border-top: 1PX solid #fff;
}

.main-content-mobile {
  padding-top: 6.8rem;
  position: relative;
  box-sizing: border-box;
}

.main-content-mobile .content-box {
  padding: 1.5rem 4.5rem;
  box-sizing: border-box;
  width: 100%;
}

.main-content-mobile .mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0,0,0,.6);
  transition: all .3s;
  opacity: 0;
  pointer-events: none;
}

.main-content-mobile .mask.on {
  opacity: 1;
  pointer-events: auto;
}

.mobile-btn {
  padding: 0.6rem 1.2rem;
  border-radius: 0.8rem;
  color: #fff;
  font-size: 1.3rem;
  background: teal;
  display: inline-block;
  margin-right: 1rem;
}

.page-mobile {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.page-mobile.padding-top {
  padding-top: 2rem;
}

.page-mobile .fixed-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}

.page-mobile .bg-wrap-box {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-mobile .bg-content {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
  will-change: transform;
}

.page-mobile .bg-content .video-ele {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.page-mobile .layer-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.6);
}

.page-mobile .page-mobile-content {
  position: relative;
  z-index: 2;
  min-height: calc(100vh - 6.8rem);
}

.page-mobile .page-mobile-content .content-box {
  min-height: calc(100vh - 13.6rem);
  overflow: hidden;
}

.page-mobile .page-title {
  font-size: 2.9rem;
  color: #fff;
  font-weight: bold;
}

.page-mobile .page-desc {
  font-size: 1.4rem;
  color: #fff;
}

.page-mobile .content-blocks {
  width: 100%;
  height: auto;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  border-radius: 2.4rem;
  border: 1PX solid #fff;
}

.page-mobile .bottom-btns {
  position: -webkit-sticky;
  position: sticky;
  height: 6.8rem;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.page-mobile .bottom-btns .btns-box {
  width: 5rem;
  position: absolute;
  right: 3rem;
  bottom: 1rem;
}

.page-mobile .bottom-btns .mobile-btn {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  margin-bottom: 1rem;
  background: none;
  padding: 0;
}

.page-mobile .bottom-btns .mobile-btn img {
  width: 100%;
  height: auto;
}

.page-mobile .bottom-btns .page-mobile {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
}

.page-mobile .look-more {
  width: 10rem;
  height: 2.7rem;
  background: #fff;
  border-radius: 0.9rem;
  font-size: 1.3rem;
  color: #000;
  margin: 0 auto;
  margin-top: 2rem;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame-videos-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.frame-videos-content .loading {
  display: block;
  position: absolute;
  width: 5rem;
  height: auto;
  left: 50%;
  top: 50%;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  opacity: .5;
}

.frame-videos {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.frame-videos canvas {
  height: auto !important;
}