.page-index-mobile {
  z-index: 3;
}

.page-index-mobile .video-ele-box {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all .3s;
  opacity: 0;
  overflow: hidden;
}

.page-index-mobile .video-ele-box.on {
  opacity: 1;
}

.page-index-mobile .video-ele-box .video-ele {
  width: 100%;
  height: auto;
  display: block;
}

.page-index-mobile .btns {
  width: 34rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 3.2rem;
}

.page-index-mobile .btns .mobile-btn {
  margin: 0;
}

.page-index-mobile .frame-videos {
  min-height: 30rem;
  height: calc(100vh - 6.8rem);
  background-size: 100% auto;
  background-position: top center;
  overflow: hidden;
}