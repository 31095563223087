.page-contact .image-item {
  height: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto calc(100vh + 30rem);
  background-clip: content-box;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/contact-banner.jpg);
}

.page-contact .banner-title {
  color: #fff;
  width: 15rem;
  position: absolute;
  left: 0;
  bottom: 8rem;
}

.page-contact .banner-title .sub {
  font-size: 1.8rem;
}

.page-contact .banner-title .big {
  font-size: 3.6rem;
  font-weight: bold;
}

.page-contact .banner .main-content-box {
  height: 100%;
}