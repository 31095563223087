.page-team .banner {
  background: #1e2126;
}

.page-team .banner .title {
  width: 80%;
}

.page-team .banner .swiper-button-next {
  right: 2rem;
}

.page-team .banner .swiper-button-prev {
  left: 2rem;
}

.page-team .banner .swiper-button-next:after,
.page-team .banner .swiper-button-prev:after {
  font-size: 3rem;
  color: #fff;
}

.page-team .banner-box {
  top: 0;
}

.page-team .banner-items {
  position: relative;
}

.page-team .banner .image-item {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.page-team .banner .image-item .image-item-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.page-team .banner .image-item .image-item-image {
  position: relative;
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 100%;
  transform-origin: center bottom;
  transition: all .3s;
  position: relative;
}

.page-team .banner .image-item .image-item-image:hover {
  transform: scale(1.1);
}

.page-team .banner .image-item .image-item-image.remove {
  transform-origin: center center;
  transform: scale(0.8);
}

.page-team .banner .image-item.move {
  transition: all .3s;
  box-shadow: 0 0 1rem rgba(0,0,0,.5);
}

.page-team .banner .image-item.move.left {
  transform: translateX(100%);
}

.page-team .banner .image-item.move.right {
  transform: translateX(-100%);
}

.page-team .banner .image-item .infos {
  position: absolute;
  left: 1.5rem;
  top: 6rem;
}

.page-team .banner .image-item .infos .name {
  font-size: 2.5rem;
  color: #fff;
  width: 2.5rem;
  padding: 0 0.4rem;
  font-weight: bold;
  line-height: 1.2;
}

.page-team .banner .image-item .infos .info {
  font-size: 0.8rem;
  color: #fff;
  width: 0.8rem;
  padding: 0 0.15rem;
}