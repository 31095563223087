.page-content {
  padding-top: 8.75rem;
  background: #cbcbcb;
}

.page-content .back {
  width: 4.5rem;
  margin-bottom: 4.5rem;
  cursor: pointer;
}

.page-content .content-html {
  width: 41rem;
  padding-bottom: 5rem;
}

.page-content .content-html .title {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1PX solid #3e3a39;
  font-size: 1.6rem;
  line-height: 2.7rem;
  position: relative;
  margin-bottom: 2.5rem;
  font-weight: bold;
}

.page-content .content-html .title .date {
  position: absolute;
  right: 0;
  bottom: 1rem;
  line-height: 1rem;
  font-size: 0.9rem;
  color: #606060;
  font-weight: lighter;
}

.page-content .recommended {
  top: 0;
  width: 17.2rem;
}

.page-content .recommended img {
  width: 100%;
  display: block;
}

.page-content .recommended .title {
  margin-bottom: 2rem;
}

.page-content .recommended .list .item.first {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1PX solid #c9c9c9;
}

.page-content .recommended .list .item.first .content-info {
  width: 10.25rem;
}

.page-content .recommended .list .item {
  margin-bottom: 2rem;
  width: 14.1rem;
}

.page-content .recommended .list .item .no {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  display: block;
  line-height: 1.2rem;
  text-align: center;
  color: #fff;
  background: #666;
  font-size: 0.8rem;
}

.page-content .recommended .list .item .no-image {
  width: 6rem;
  height: 3rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 0.4rem;
}

.page-content .recommended .list .item .no-image .no {
  position: absolute;
  left: -0.6rem;
  top: -0.6rem;
  background: #018080;
}

.page-content .recommended .list .item .no-image .desc {
  font-size: 0.8rem;
}

.page-content .recommended .list .item .desc {
  font-size: 0.7rem;
  line-height: 1.5;
  color: #333;
}

.page-content .recommended .list .item .date {
  font-size: 0.6rem;
  line-height: 1;
  color: #606060;
}

.page-content .recommended .list .item .content-info {
  width: 12.1rem;
}

.page-content .recommended .more-btns {
  align-items: flex-end;
  cursor: pointer;
}

.page-content .recommended .more-btns .more-btn {
  width: 6.2rem;
  height: 1.5rem;
  border: 1PX solid #7d7d7d;
  border-radius: 6.1rem;
  margin-bottom: 3rem;
}

.page-content .recommended .more-btns .look-btn {
  width: 8rem;
  margin-bottom: 1rem;
}

.page-content .recommended .more-btns .si-btn {
  width: 2.1rem;
  margin-bottom: 1rem;
}

.page-content .recommended .more-btns .si-btn.back {
  width: 7.7rem;
}