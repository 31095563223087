.page-investment-mobile .page-subtitle {
  font-size: 2rem;
  margin-top: 2.5rem;
  color: #fff;
  line-height: 2.4rem;
  height: 3.5rem;
  font-weight: lighter;
}

.page-investment-mobile .page-subtitle span {
  padding-bottom: 0.5rem;
  border-bottom: 1PX solid #fff;
}

.page-investment-mobile .page-subdesc {
  margin-top: 0.5rem;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: #fff;
}

.page-investment-mobile .page-subdesc.font-size26 {
  font-size: 1.3rem;
}

.page-investment-mobile .page-subdesc.margin-top40 {
  margin-top: 2rem;
}

.page-investment-mobile .logo-list {
  width: 100%;
  margin-top: 1.5rem;
  perspective: 30rem;
}

.page-investment-mobile .logo-list .logo {
  width: 8.8rem;
  background: #fff;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
}

.page-investment-mobile .logo-list .logo img {
  width: 100%;
  display: block;
  height: auto;
}