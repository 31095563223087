.page-team-mobile .swiper-box {
  width: 100%;
  background: #000;
  height: calc(100vh - 6.8rem);
  min-height: 30rem;
}

.page-team-mobile .swiper-box .slide-item {
  height: 33.33%;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.page-team-mobile .swiper-box .slide-item .layer-click {
  width: 100%;
  height: 100%;
}

.page-team-mobile .swiper-box .slide-item .infos {
  position: absolute;
  left: 5rem;
  top: 6rem;
}

.page-team-mobile .swiper-box .slide-item .infos .name {
  font-size: 3.6rem;
  color: #fff;
  width: 4.6rem;
  font-weight: bold;
  line-height: 1.1;
  margin-top: -0.2rem;
}

.page-team-mobile .swiper-box .slide-item .infos .info {
  font-size: 0.9rem;
  color: #fff;
  width: 1.2rem;
  line-height: 1.2;
}

.page-team-mobile .swiper-box .swiper-button-next {
  right: 2rem;
}

.page-team-mobile .swiper-box .swiper-button-prev {
  left: 2rem;
}

.page-team-mobile .swiper-box .swiper-button-next:after,
.page-team-mobile .swiper-box .swiper-button-prev:after {
  font-size: 3rem;
  color: #fff;
}

.page-team-mobile .swiper-box .swiper-pagination-bullet {
  width: 0.8rem;
  height: 0.8rem;
  background: #fff;
}