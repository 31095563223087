.page-auto .image-item {
  height: 100%;
}

.page-auto .image-item,
.page-auto .image-item .layer-bg-baseMap {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto calc(100vh + 30rem);
  background-clip: content-box;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/about-banner.jpg);
  position: relative;
}

.page-auto .image-item .layer-bg-baseMap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/about-banner-highlight.jpg);
}

.page-auto .image-item .layer-bg-black {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
}

.page-auto .banner .data-info {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 5rem;
  width: 70rem;
}

.page-auto .banner .data-info .auto-title {
  color: #fff;
  width: 15rem;
}

.page-auto .banner .data-info .auto-title .sub {
  font-size: 1.8rem;
}

.page-auto .banner .data-info .auto-title .big {
  font-size: 3.6rem;
  font-weight: bold;
}

.page-auto .banner .data-info .data-list {
  width: 32rem;
  color: #fff;
}

.page-auto .banner .data-info .data-list .data-item {
  width: 33.33%;
}

.page-auto .banner .data-info .data-list .data-item .number {
  font-size: 3rem;
}

.page-auto .banner .data-info .data-list .data-item .number .unit {
  font-size: 2.25rem;
}

.page-auto .banner .data-info .data-list .data-item .number .desc {
  font-size: 0.8rem;
}

.page-auto .slide-2 {
  background: #c9c9c9;
  align-items: flex-end;
  overflow: hidden;
  top: 0;
}

.page-auto .slide-2.top-auto {
  top: auto;
}

.page-auto .slide-2 .text-box {
  width: 50%;
  height: 100%;
  padding: 3rem 1.5rem;
  box-sizing: border-box;
}

.page-auto .slide-2 .text-box .width-520 {
  width: 26rem;
}

.page-auto .slide-2 .text-box .width-540 {
  width: 27rem;
}

.page-auto .slide-2 .tex {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  font-size: 1.05rem;
  line-height: 1.6rem;
  color: #3e3a39;
  transform-origin: right center;
  text-align: justify;
}

.page-auto .slide-2 .tex .sp1 {
  display: block;
  width: 6rem;
}

.page-auto .slide-2 .tex .sp2 {
  display: block;
  width: 27rem;
}

.page-auto .slide-2 .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  transform-origin: left center;
  width: 50%;
  height: 100%;
  position: relative;
}

.page-auto .slide-2 .image .auto-title {
  position: absolute;
  left: 10rem;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

.page-auto .slide-2 .image .auto-title .sub {
  font-size: 1.8rem;
}

.page-auto .slide-2 .image .auto-title .big {
  font-size: 3.6rem;
  font-weight: bold;
}

.page-auto .slide-2.right .tex {
  float: left;
  transform-origin: left center;
}

.page-auto .slide-2.right .image .auto-title {
  left: auto;
  right: 10rem;
  transform-origin: right center;
}

.page-auto .slide-2 .img1 {
  width: 28.1rem;
  height: auto;
  margin-top: 2rem;
}

.page-auto .slide-2 .img1 img {
  height: auto;
  width: 100%;
  display: block;
}