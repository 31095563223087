.page-contact-mobile .content-blocks-box {
  margin-top: 1.5rem;
}

.page-contact-mobile .content-blocks-box .content-block {
  padding: 3rem 0;
  border-bottom: 1PX solid rgba(255,255,255,.5);
  margin-top: 1.5rem;
}

.page-contact-mobile .content-blocks-box .content-block .look-btn {
  width: 16rem;
  height: 3.5rem;
  border-radius: 2rem;
  background: #018080;
  color: #fff;
  line-height: 3.5rem;
  font-size: 1.7rem;
  text-align: center;
}

.page-contact-mobile .content-blocks-box .content-block .page-subtitle {
  line-height: 1;
  font-size: 2rem;
  color: #fff;
}

.page-contact-mobile .content-blocks-box .content-block .page-subdesc {
  line-height: 1;
  font-size: 1.3rem;
  color: #fff;
  width: 110%;
}

.page-contact-mobile .content-blocks-box .content-block .page-subdesc.margin-top20 {
  margin-top: 1.5rem;
}

.page-contact-mobile .content-blocks-box .content-block .qr {
  width: 8.2rem;
  height: 8.2rem;
  background: #fff;
  margin-right: 1rem;
}

.page-contact-mobile .content-blocks-box .content-block .qr img {
  width: 100%;
  height: auto;
}

.page-contact-mobile .content-blocks-box .content-block.qr-block {
  align-items: flex-end;
  border-bottom: none;
}

.page-contact-mobile .content-blocks-box .content-block.qr-block .page-subdesc {
  width: auto;
  line-height: 2.2rem;
}