.page-about-mobile .page-mobile-content .content-box {
  min-height: auto;
}

.page-about-mobile .content-blocks-box {
  position: relative;
  margin-bottom: 1rem;
}

.page-about-mobile .content-blocks-box .more-details {
  position: absolute;
  right: 2.4rem;
  bottom: -2.1rem;
  width: 10rem;
  height: 2.1rem;
  background: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: teal;
  line-height: 2.1rem;
  text-align: center;
  font-size: 1.3rem;
}

.page-about-mobile .introduce {
  color: #fff;
  font-size: 1.3rem;
  line-height: 2.4rem;
  text-align: justify;
}

.page-about-mobile .content-blocks {
  margin-top: 1.5rem;
  position: relative;
}

.page-about-mobile .content-blocks .count {
  line-height: 6rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.page-about-mobile .content-blocks .count .num {
  font-size: 5.6rem;
  vertical-align: middle;
}

.page-about-mobile .content-blocks .count .unit {
  font-size: 4.3rem;
  vertical-align: middle;
  padding-left: 0.5rem;
}

.page-about-mobile .content-blocks .desc {
  font-size: 1.3rem;
  color: #fff;
}

.page-about-mobile .content-blocks .desc.font-size32 {
  font-size: 1.6rem;
}

.page-about-mobile .content-blocks .desc.font-size42 {
  font-size: 2rem;
}

.page-about-mobile .content-blocks .desc.font-size26 {
  font-size: 1.3rem;
}

.page-about-mobile .content-blocks .realm {
  width: 21rem;
  margin-top: 1rem;
}

.page-about-mobile .content-blocks .honor-list {
  overflow: hidden;
}

.page-about-mobile .content-blocks .honor-list .honor-item {
  padding: 2rem 0;
  border-bottom: 1PX solid #fff;
}

.page-about-mobile .content-blocks .honor-list .honor-item:last-child {
  border-bottom: none;
}

.page-about-mobile .content-blocks .honor-list .honor-item .desc {
  font-size: 1.3rem;
}

.page-about-mobile .content-blocks .honor-list .honor-item .honor-icon {
  width: 6rem;
  height: auto;
}

.page-about-mobile .content-blocks .honor-list .honor-item .honor-icon img {
  width: 100%;
  height: auto;
}

.page-about-mobile .content-blocks .realm-img {
  width: 100%;
}

.page-about-mobile .content-blocks .realm-img img {
  width: 100%;
  height: auto;
}