.page-content-mobile {
  min-height: 100vh;
  background: #cbcbcb;
}

.page-content-mobile .title {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1PX solid #3e3a39;
  font-size: 1.4rem;
  line-height: 2.7rem;
  position: relative;
  margin-bottom: 2.5rem;
  font-weight: bold;
}

.page-content-mobile .title .date {
  position: absolute;
  right: 0;
  bottom: 1rem;
  line-height: 1rem;
  font-size: 0.9rem;
  color: #606060;
}

.page-content-mobile .back {
  width: 4.4rem;
  position: absolute;
  left: 2rem;
  top: 8rem;
  z-index: 2;
}

.page-content-mobile .back img {
  width: 100%;
  height: auto;
}

.page-content-mobile .cover {
  margin-bottom: 5rem;
  height: 30.7rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center -15rem;
  background-size: 100%;
}

.page-content-mobile .teams .content-html {
  margin-top: 6rem;
}