.page-news .image-item {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto calc(100vh + 30rem);
  background-clip: content-box;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/news-banner.jpg);
}

.page-news .banner-title {
  color: #fff;
  width: 15rem;
  position: absolute;
  left: 0;
  bottom: 8rem;
}

.page-news .banner-title .sub {
  font-size: 1.8rem;
}

.page-news .banner-title .big {
  font-size: 3.6rem;
  font-weight: bold;
}

.page-news .banner .main-content-box {
  height: 100%;
}

.page-news .slide-2 {
  background: #fff;
  height: auto;
  padding: 3rem 0;
}

.page-news .slide-2 .sub-title {
  font-size: 2rem;
  color: #202328;
  margin-bottom: 3.5rem;
}

.page-news .slide-2 .list-items {
  width: 100%;
}

.page-news .slide-2 .list-items .item-li {
  cursor: pointer;
}

.page-news .slide-2 .list-items .item-li.on {
  background: #1b1e23;
}

.page-news .slide-2 .list-items .item-li.on .infos .new-title,
.page-news .slide-2 .list-items .item-li.on .infos .date,
.page-news .slide-2 .list-items .item-li.on .infos .desc {
  color: #fff;
}

.page-news .slide-2 .list-items .item {
  padding: 2rem 0;
  height: 11.6rem;
  border-bottom: 1PX solid #e9e9e9;
}

.page-news .slide-2 .list-items .item .image {
  width: 23.1rem;
  height: 11.6rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-clip: content-box;
  background-color: #c9c9c9;
  background-size: cover;
}

.page-news .slide-2 .list-items .item .infos {
  width: 37rem;
  height: 11.6rem;
}

.page-news .slide-2 .list-items .item .infos .new-title {
  font-size: 1.6rem;
  color: #3e3a39;
  line-height: 1.2;
}

.page-news .slide-2 .list-items .item .infos .date {
  font-size: 1rem;
  color: #606060;
  margin-top: 0.6rem;
}

.page-news .slide-2 .list-items .item .infos .desc {
  margin-top: 2rem;
  font-size: 0.8rem;
  color: gray;
  line-height: 1.2;
}

.page-news .slide-2 .btns {
  margin: 5rem auto;
}

.page-news .slide-2 .btns .btn {
  width: 9.6rem;
  height: 2.8rem;
  line-height: 2.6rem;
  color: #fff;
  margin-right: 2.5rem;
  background-color: teal;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/page-index-block2-arrow.png);
  background-position: 2rem center;
  background-size: 1rem;
  background-repeat: no-repeat;
  padding-left: 3.6rem;
  font-size: 1rem;
  border-radius: 2rem;
  box-sizing: border-box;
  cursor: pointer;
}