.page-news-mobile {
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/mobile/news-bg.jpg);
}

.page-news-mobile .content-blocks-box {
  margin-top: 2rem;
}

.page-news-mobile .content-blocks-box .content-block {
  color: #fff;
  padding: 2rem 0;
  border-top: 1PX solid rgba(255,255,255,.5);
}

.page-news-mobile .content-blocks-box .content-block .title {
  font-size: 1.4rem;
  line-height: 1.5;
}

.page-news-mobile .content-blocks-box .content-block .info {
  margin-top: 1rem;
}

.page-news-mobile .content-blocks-box .content-block .info .date {
  font-size: 1rem;
  line-height: 1.5rem;
}

.page-news-mobile .content-blocks-box .content-block .info .btn {
  width: 6rem;
  height: 1.5rem;
  border-radius: 0.4rem;
  border: 1PX solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-news-mobile .look-more {
  margin-top: -1rem;
  margin-bottom: 2rem;
}