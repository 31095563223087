.page-index .layer-bg {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  position: absolute;
  left: 0;
  top: 0;
}

.page-index .banner-images {
  display: none;
}

.page-index .banner .image-item-bg {
  background-size: auto calc(100vh + 20rem);
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/index-banner-highlight.jpg);
  background-position: center center;
}

.page-index .banner .title {
  letter-spacing: 0.1rem;
}

.page-index .banner .image-item {
  position: relative;
}

.page-index .banner .image-item.image-item-content {
  position: absolute;
  left: 0;
  top: 0;
}

.page-index .banner .image-item.transition {
  transition: all 1s;
}

.page-index .banner .video-ele {
  height: 100%;
  display: block;
  width: 100%;
  object-fit: cover;
}

.page-index .btns .btn {
  width: 9.6rem;
  height: 2.8rem;
  line-height: 2.6rem;
  color: #fff;
  margin-right: 2.5rem;
  background-color: teal;
  background-image: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/page-index-block2-arrow.png);
  background-position: 2rem center;
  background-size: 1rem;
  background-repeat: no-repeat;
  padding-left: 3.6rem;
  font-size: 1rem;
  border-radius: 2rem;
  box-sizing: border-box;
  cursor: pointer;
}

.page-index .btns .btn .no-margin {
  margin-right: 0;
}

.page-index .slide-2 {
  background: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/page-index-block2-bg.jpg) no-repeat center top;
  background-size: auto calc(100% + 100vh);
}

.page-index .slide-2 .main-content-box {
  width: 52rem;
  box-sizing: border-box;
}

.page-index .slide-2 .title {
  font-size: 2.25rem;
  color: #fff;
  line-height: 1;
  margin-bottom: 3.5rem;
}

.page-index .slide-2 .data-list {
  margin-bottom: 1.5rem;
}

.page-index .slide-2 .data-list .data-item {
  width: 12.6rem;
}

.page-index .slide-2 .data-list .data-item .data-number {
  font-size: 3rem;
  color: #fff;
  line-height: 3rem;
  margin-bottom: 1rem;
}

.page-index .slide-2 .data-list .data-item .data-number .unit {
  font-size: 2.25rem;
}

.page-index .slide-2 .data-list .data-item .info {
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
}

.page-index .slide-2 .info-bottom {
  font-size: 1.05rem;
  color: #fff;
  line-height: 1.5rem;
  margin-bottom: 2.6rem;
  text-align: justify;
}

.page-index .slide-3 {
  background: url(//guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc/images/page-index-block3-bg.jpg) no-repeat center top;
  background-size: auto 100%;
  height: auto;
  min-height: 47.5rem;
}

.page-index .slide-3 .main-content-box {
  position: relative;
  z-index: 2;
}

.page-index .slide-3 .title {
  font-size: 2.25rem;
  color: #fff;
  text-align: center;
  margin-bottom: 3.4rem;
  margin-top: 5rem;
}

.page-index .slide-3 .new-list {
  margin-bottom: 4rem;
  perspective: 30rem;
}

.page-index .slide-3 .new-list .item {
  width: 15rem;
  color: #fff;
  height: 30rem;
  position: relative;
}

.page-index .slide-3 .new-list .item .img {
  width: 100%;
  display: block;
  margin-bottom: 2.2rem;
  height: 7.8rem;
  background: #e9e9e9;
}

.page-index .slide-3 .new-list .item .sub-title {
  font-size: 1.15rem;
  color: #fff;
  line-height: 1.8rem;
  height: 3.6rem;
  margin-bottom: 2.4rem;
  overflow: hidden;
  text-align: justify;
}

.page-index .slide-3 .new-list .item .text {
  text-align: justify;
  font-size: 0.8rem;
  color: #fff;
  line-height: 1.2rem;
  padding-top: 1.5rem;
  position: relative;
  margin-bottom: 2rem;
}

.page-index .slide-3 .new-list .item .text:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 1.6rem;
  height: 0.3rem;
  background: #fff;
}

.page-index .slide-3 .new-list .item .date {
  font-size: 2.1rem;
  line-height: .5;
  position: absolute;
  left: 0;
  bottom: 0;
}

.page-index .slide-3 .new-list .item .date span {
  font-size: 0.6rem;
  line-height: 1rem;
}

.page-index .slide-3 .btns {
  margin-bottom: 3rem;
}